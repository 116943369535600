#settings {
  .info-container {
    background-color: $theme-hue;
    height: 208px;
    padding: 0 35px 20px 35px;
    @include screen-mobile() {
      height: auto;
    }
    .user_info {
      @include screen-mobile() {
        display: block;
      }
      .item {
        width: 260px;
        padding-top: 20px;
        padding-bottom: 20px;
        i {
          color: $white;
          font-size: 16px;
          font-style: normal;
        }
        span {
          color: rgba($color: #fff, $alpha: 0.8);
          margin-top: 10px;
        }
      }
    }
  }
  .regist-container {
    background-color: $theme-hue;
    height: 100px;
    border-top: 1px solid $graphite;
    padding-left: 35px;
    padding-top: 20px;
    i {
      color: $white;
      font-style: normal;
      font-size: 16px;
    }
    span {
      color: rgba($color: #fff, $alpha: 0.8);
      margin-top: 10px;
    }
  }
}
